<template>
<div>
    <div class="here bg-fff pt-4 mb-3" v-if="validador($route.meta.typePerfilVerificaRouta, getUserDatas.profile_id)">
        <div class="container bg-fff in-here">
            <div class="row">
                <div class="col-md-12 mx-auto text-left">
                    <graficoNumeroDeCadastros :bg_color="'bg-222541'" />
                </div>
                <!--
                    <div class="col-md-12 mx-auto text-left">
                    <turmasMaisExerciciosRealizados :bg_color="'bg-222541'" />
                </div>
                -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import turmasMaisExerciciosRealizados from '../components/investidor/turmasMaisExerciciosRealizados'
import graficoNumeroDeCadastros from '../components/investidor/GraficoNumeroDeCadastros'
import {mapGetters} from 'vuex'

export default {
    components: {
        turmasMaisExerciciosRealizados,
        graficoNumeroDeCadastros
    },
    computed:{
        ...mapGetters(['getUserDatas'])
    },
}
</script>

<style scoped>

</style>
